import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  logged = new BehaviorSubject(false);
  authState = new BehaviorSubject(false);

  constructor(public angularFireAuth: AngularFireAuth,
              private router: Router,
              private firestore: AngularFirestore,
              private alertController: AlertController) {
               this.checkLoged();
              }

  // login
  async onLogin(usuario: string, clave: string) {
    try {
      return await this.angularFireAuth.signInWithEmailAndPassword(
        usuario,
        clave
      ).then(async (res) => {
        if (res) {

        }
      });
    } catch (error) {
      console.log(error);
      return 'error';
    }
  }

  logOut() {
    this.angularFireAuth.signOut().then(res => {
      this.authState.next(false);
      this.router.navigate(['login']);
    });
  }

  isAuthenticated() {
    return this.authState.value;
  }

  isUserAuth(){
    return this.angularFireAuth.authState;
  }

  isLogin() {
    return this.authState;
  }

  async checkLoged() {
    await this.angularFireAuth.onAuthStateChanged(async user => {
      if (user) {
        // User is signed in.
        console.log('usuario está logeado');
        await this.firestore.collection('users')
          .doc(user.uid)
          .get()
          .toPromise()
          .then((role: any) => {
            if (role.data().role === 'admin') {
              this.logged.next(true);
              this.authState.next(true);
              this.router.navigate(['/inicio'], { replaceUrl: true });
            } else {
              this.presentAlert('Tu tipo de cuenta solo puede acceder desde la aplicación móvil', 'No tienes acceso!');
              this.logOut();
            }
          });/*
        this.authState.next(true);
        this.router.navigate(['/inicio'], { replaceUrl: true }); */
      } else {
        // No user is signed in.
        console.log('usuario NO está logeado');
        this.router.navigate(['/login'], { replaceUrl: true });
      }
    });
  }

  async presentAlert(message: string, header: string) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK']
    });
    await alert.present();
  }

}
