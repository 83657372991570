/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { TipoFaena } from '../interfaces/tipo-faena';
import { Empresa } from '../interfaces/empresa';
import { TrabajoReportado } from '../interfaces/trabajo-reportado';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService implements OnDestroy {

  public tiposFaena = new BehaviorSubject([]);
  subTiposFaena: any;

  constructor(private firestore: AngularFirestore) {
   }

  ngOnDestroy() {
    this.subTiposFaena.unsubscribe();
  }

  getImagenes(doc: string) {
    return this.firestore.collection('trabajos_reportados')
                         .doc(doc)
                         .collection('imagenes')
                         .snapshotChanges();
  }

  getReportados(fechas: any) {
    const refQuery = this.firestore.collection('trabajos_reportados',
                                           ref =>
                                           ref.where('faena.fecha_registro','>=', fechas.desde)
                                           .where('faena.fecha_registro','<=', fechas.hasta)
                                           .orderBy('faena.fecha_registro')
                                           );
    return refQuery.snapshotChanges();
  }

  fetchListaTipoFaena(): Observable<TipoFaena[]> {
    return this.tiposFaena.asObservable();
  }

  addTipoFaena(nuevoTipo: TipoFaena){
    return this.firestore.collection('tipos_faena')
                          .add({
                                tipo_faena: nuevoTipo.tipo_faena,
                                medida_avance: nuevoTipo.medida_avance
                              });
  }

  deleteTipoFaena(id: string){
    return this.firestore.collection('tipos_faena').doc(id).delete();
  }

  updateTipoFaena(id: string, datos: TipoFaena){
    return this.firestore.collection('tipos_faena')
                         .doc(id)
                         .set({
                               tipo_faena: datos.tipo_faena,
                               medida_avance: datos.medida_avance
                          });
  }

  getListTiposFaena(){
    const refData = this.firestore.collection('tipos_faena',
      ref =>
        ref.orderBy('tipo_faena')
    );
    /* const dataRes = */
    this.subTiposFaena = refData.snapshotChanges().subscribe((tipos: any) => {
      const items: TipoFaena[] = [];
      tipos.forEach((tipo: any) => {
        items.push({
          id: tipo.payload.doc.id,
          tipo_faena: tipo.payload.doc.data().tipo_faena,
          medida_avance: tipo.payload.doc.data().medida_avance
        });
      });
      this.tiposFaena.next(items);
      this.subTiposFaena.unsubscribe();
    });
  }

  async getTiposFaena(ultimoTipoFaena){
    const refData = this.firestore.collection('tipos_faena',
                                           ref =>
                                           ref.orderBy('tipo_faena')
                                               .startAfter(ultimoTipoFaena || 0)
                                              .limit(10)
                                          );
    /* const dataRes = */
    return refData.snapshotChanges();
  }

  getEmpresas(){
    const refData = this.firestore.collection('empresas',
      ref =>
        ref.orderBy('nombre_empresa')
    );
    return refData.snapshotChanges();
  }

  checkEmpresa(empresa: Empresa){
    const refData = this.firestore.collection('empresas',
      ref =>
        ref.where('rut_empresa', '==', empresa.rut_empresa)
          .limit(1)
    );

    return refData.snapshotChanges();
  }

  async addEmpresa(empresa: Empresa){
      return this.firestore.collection('empresas').doc(empresa.rut_empresa).set(empresa);
  }



}
