import { Component, OnInit } from '@angular/core';
import { LoginService } from './services/login.service';
import { Platform } from '@ionic/angular';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isLoggedIn = false;
  user: any;
  public appPages = [
    { title: 'Inicio', url: '/inicio', icon: 'home' },
    { title: 'Reportados', url: '/reportados', icon: 'list' },
    { title: 'Usuarios', url: '/usuarios', icon: 'people' },
    { title: 'Empresas', url: '/empresas', icon: 'id-card' },
    { title: 'Tipos de Faena', url: 'tiposFaena', icon: 'clipboard' },
  ];
  // public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(private loginService: LoginService,
              private platform: Platform) { }

  ngOnInit() {
    this.platform.ready().then( () => {
      this.loginService.isLogin().subscribe( res => {
        this.isLoggedIn = res;
      });
      this.loginService.isUserAuth().subscribe( user => {
        if (user === null) {
          //this.isLoggedIn = false;
        }else{
          //this.isLoggedIn = true;
          this.user = user;
        }
      });
    });
  }

  logOutClick() {
    this.loginService.logOut();
  }

}
