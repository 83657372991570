import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { AuthGuardService } from './services/auth-guard.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TipoFaenaPage } from './formularios/tipo-faena/tipo-faena.page';

@NgModule({
  declarations: [AppComponent, TipoFaenaPage],
  entryComponents: [],
  imports: [BrowserModule,
            IonicModule.forRoot(),
            AppRoutingModule,
            AngularFireModule.initializeApp(environment.firebaseConfig),
            AngularFireAuthModule,
            ReactiveFormsModule,
            FormsModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, AuthGuardService],
  bootstrap: [AppComponent],
})
export class AppModule {}
