import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then(m => m.FolderPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'login',
    loadChildren: () => import('./paginas/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'reportados',
    loadChildren: () => import('./paginas/reportados/reportados.module').then( m => m.ReportadosPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./paginas/usuarios/usuarios.module').then(m => m.UsuariosPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'empresas',
    loadChildren: () => import('./paginas/empresas/empresas.module').then(m => m.EmpresasPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'empresa',
    loadChildren: () => import('./paginas/empresa/empresa.module').then(m => m.EmpresaPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./paginas/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'tiposFaena',
    loadChildren: () => import('./paginas/tipos-faena/tipos-faena.module').then(m => m.TiposFaenaPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'inicio',
    loadChildren: () => import('./paginas/inicio/inicio.module').then(m => m.InicioPageModule),
    canActivate: [AuthGuardService]
  }




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
