/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TipoFaena } from 'src/app/interfaces/tipo-faena';
import { FirestoreService } from '../../services/firestore.service';

@Component({
  selector: 'app-tipo-faena',
  templateUrl: './tipo-faena.page.html',
  styleUrls: ['./tipo-faena.page.scss'],
})
export class TipoFaenaPage implements OnInit {

  @Input()  accion: string;
  @Input()  datos: TipoFaena;

  tipoForm: FormGroup;

  constructor(private modalCtrl: ModalController,
              public formBuilder: FormBuilder,
              private firestoreService: FirestoreService) { }

  ngOnInit() {
      this.tipoForm = this.formBuilder.group({
        tipo_faena: [ this.datos.tipo_faena , [Validators.required]],
        medida_avance: [ this.datos.medida_avance, [Validators.required]],
      });
  }

  async saveForm(){
    switch (this.accion) {
      case 'editar':
        await this.firestoreService.updateTipoFaena(this.datos.id, this.tipoForm.value).then(res => {
          this.modalCtrl.dismiss();
        });
        break;
      case 'nuevo':
        await this.firestoreService.addTipoFaena(this.tipoForm.value).then( res => {
          this.modalCtrl.dismiss();
        });
        break;

      default:
        break;
    }
  }

  cancelButton(){
    this.modalCtrl.dismiss();
  }
}
